import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import pauserecording1 from 'img/help/pauserecording1.png';
import pauserecording2 from 'img/help/pauserecording2.png';
import pauserecording3 from 'img/help/pauserecording3.png';
import pauserecording4 from 'img/help/pauserecording4.png';
import pauserecording5 from 'img/help/pauserecording5.png';

const Content = () => {
  return (
    <div>
      <p>To pause recording:</p>
      <ol>
        <li>
          <strong>Go to Settings</strong> &#8594;<strong> Websites</strong>. Find a website you
          would like to edit and click on “Settings” on the right side:
          <Image src={pauserecording1} alt="Pause recording" title="Go to settings" />
        </li>
        <li>
          You’ll see a pop-up window like this one:
          <Image src={pauserecording2} lazy alt="Pause recording" title="Pop-up window" />
        </li>
        <li>
          Click on the blue toggle next to “Recording enabled”:
          <Image
            src={pauserecording3}
            lazy
            alt="Pause recording"
            title="Click on the blue toggle"
          />
        </li>
        <li>
          A disabled recording feature looks like this:
          <Image
            src={pauserecording4}
            lazy
            alt="Pause recording"
            title="Disabled recording feature"
          />
        </li>
        <li>
          Click the <strong>“Update”</strong> button to save your changes:
          <Image src={pauserecording5} lazy alt="Pause recording" title="Update changes" />
        </li>
      </ol>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'How to pause recording?',
  metaDescription: 'Check how you can pause recording',
  canonical: '/help/how-to-pause-recording/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
